import React from "react"
import axios from "axios"

import { StyledSection } from "./ContactStyles"
import { useForm } from "react-hook-form"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function ContactForm({ data }) {
  const [open, setOpen] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const { register, handleSubmit, errors, reset } = useForm()

  const classes = useStyles()

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  async function onSubmitForm(values) {
    setIsDisabled(true)
    let config = {
      method: "POST",
      url: "https://lafon.coeur.pw/contacts",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    }
    try {
      const response = await axios(config)
      if (response.status === 200) {
        setOpen(true)
        reset()
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <StyledSection>
      <h2>Contact Us</h2>
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        id="form"
        className="topBefore"
      >
        <input
          name="Name"
          type="text"
          ref={register({
            required: {
              value: true,
              message: "Please enter your name",
            },
          })}
          placeholder="NAME*"
        />

        <input name="phone" type="text" ref={register} placeholder="PHONE" />
        <span>{errors?.Name?.message}</span>
        <input
          name="company"
          type="text"
          ref={register({
            required: {
              value: true,
              message: "Please enter your company name",
            },
          })}
          placeholder="COMPANY*"
        />

        <input name="State" type="text" ref={register()} placeholder="STATE" />
        <span>{errors?.company?.message}</span>
        <input
          name="email"
          type="text"
          ref={register({
            required: {
              value: true,
              message: "Please enter your email",
            },
            maxLength: {
              value: 100,
              message: "Please enter a valid email",
            },
            minLength: {
              value: 8,
              message: "Please enter a valid email",
            },
            pattern: {
              value: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
              message: "Please enter a valid email",
            },
          })}
          placeholder="E-MAIL*"
        />

        <textarea
          name="message"
          type="text"
          ref={register({
            required: {
              value: true,
              message: "Please enter a message",
            },
            maxLength: {
              value: 1000,
              message: "Your message can't be more than 1000 characters",
            },
          })}
          placeholder="MESSAGE*"
        ></textarea>
        <span>{errors?.email?.message}</span>
        <button
          name="submit"
          id="submit"
          type="submit"
          ref={register}
          disabled={isDisabled}
        >
          SEND!
        </button>
        <span>{errors?.message?.message}</span>
      </form>
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="success" className="text-sm">
            Your message was sent successfully!
          </Alert>
        </Snackbar>
      </div>
    </StyledSection>
  )
}
