import React from "react"
import ContactForm from "../components/Contact/ContactForm"
import ImageCover from "../components/ImageCover"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default function Contact({ data }) {
  return (
    <Layout>
      <ImageCover
        image={
          data.MyQuery.Cover.CoverImage.localFile.childImageSharp
            .gatsbyImageData
        }
        heading={data.MyQuery.Cover.Headline}
        para={data.MyQuery.Cover.Paragraph}
        section={"#form"}
        third
        MainCover={true}
      />
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query {
    MyQuery: strapiContactPage {
      Cover {
        Headline
        CoverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
      }
    }
  }
`
