import styled from "styled-components"

export const StyledSection = styled.section`
  h2 {
    text-align: center;
    padding-top: 40px;
  }
  font-size: 1.6rem;
  span {
    font-size: 1.2rem;
    font-weight: 400;
    right: 0;
    position: absolute;
    color: #c21c06;
    margin-top: -32px;
    margin-right: 40px;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #aca49c;
    font-size: 0.875em;
  }

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: #bbb5af;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #aca49c;
    font-size: 0.875em;
  }

  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: #bbb5af;
  }

  input::placeholder,
  textarea::placeholder {
    color: #aca49c;
    font-size: 0.875em;
  }

  input:focus::placeholder,
  textarea::focus:placeholder {
    color: #bbb5af;
  }

  input::-ms-placeholder,
  textarea::-ms-placeholder {
    color: #aca49c;
    font-size: 0.875em;
  }

  input:focus::-ms-placeholder,
  textarea:focus::-ms-placeholder {
    color: #f2f2f2;
  }

  /* on hover placeholder */

  input:hover::-webkit-input-placeholder,
  textarea:hover::-webkit-input-placeholder {
    color: #f2f2f2;
    font-size: 0.875em;
  }

  input:hover:focus::-webkit-input-placeholder,
  textarea:hover:focus::-webkit-input-placeholder {
    color: #cbc6c1;
  }

  input:hover::-moz-placeholder,
  textarea:hover::-moz-placeholder {
    color: #e2dedb;
    font-size: 0.875em;
  }

  input:hover:focus::-moz-placeholder,
  textarea:hover:focus::-moz-placeholder {
    color: #cbc6c1;
  }

  input:hover::placeholder,
  textarea:hover::placeholder {
    color: #e2dedb;
    font-size: 0.875em;
  }

  input:hover:focus::placeholder,
  textarea:hover:focus::placeholder {
    color: #cbc6c1;
  }

  input:hover::placeholder,
  textarea:hover::placeholder {
    color: #e2dedb;
    font-size: 0.875em;
  }

  input:hover:focus::-ms-placeholder,
  textarea:hover::focus:-ms-placeholder {
    color: #cbc6c1;
  }

  body {
    //font-family: "Open Sans", sans-serif;
    background: #e2dedb;
    color: #b3aca7;
  }

  header {
    position: relative;
    margin: 40px 0 25px 0;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--grey);
  }

  #form {
    position: relative;
    width: 500px;
    margin: 50px auto 40px auto;
  }

  input,
  button {
    //font-family: "Lato", sans-serif;
    font-size: 0.875em;
    width: 470px;
    height: 50px;
    padding: 0px 15px 0px 15px;

    background: transparent;
    outline: none;
    color: #726659;

    border: solid 1px #b3aca7;
    border-bottom: none;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }

  input:hover {
    background: #b3aca7;
    color: #e2dedb;
  }

  textarea {
    width: 470px;
    max-width: 470px;
    height: 110px;
    max-height: 110px;
    padding: 15px;

    background: transparent;
    outline: none;

    color: #726659;
    //font-family: "Open Sans", sans-serif;
    font-size: 0.875em;

    border: solid 1px #b3aca7;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }

  textarea:hover {
    background: #b3aca7;
    color: #e2dedb;
  }

  #submit {
    width: 470px;

    padding: 0;
    margin: -5px 0px 0px 0px;

    font-size: 0.875em;
    color: #b3aca7;

    outline: none;
    cursor: pointer;

    border: solid 1px #b3aca7;
    border-top: none;
  }

  #submit:hover {
    color: #e2dedb;
  }

  .MuiSvgIcon-root {
    display: none;
  }

  @media only screen and (max-width: 500px) {
    input,
    button {
      max-width: 100%;
    }
    #form {
      max-width: 312px;
    }

    textarea {
      max-width: 100%;
    }
  }
`
